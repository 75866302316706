import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './classAuth.css'; // Ensure you import the CSS file for styling


const SchoolList = () => {
    return (
        <div className="class-auth">
            <div className="class-auth-header">
                <div className='logo-container'>
                    <img src="/tooig-classroom-logo.png" alt="your-logo-here" className='logo' />
                    <div className='image-cover'></div>
                </div>
            </div>
            <div className="class-auth-body">
                <h1>Select School</h1>
                <div className='class-auth-form'>
                    <div className='input-container'>
                        <input className='class-auth-form-input' type="text" placeholder="Please type in school name" />
                        <span className='bottom-line'></span>
                    </div>
                    <button className='send-btn'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
                            <path d="M0.936578 7.09482L11.7814 7.09481L8.17961 10.7087C7.82762 11.063 7.82762 11.6376 8.17961 11.992C8.5316 12.3416 9.10019 12.3416 9.45218 11.992L14.5973 6.8338C14.9493 6.48109 14.9493 5.91249 14.5973 5.5605L9.45218 0.43064C9.10019 0.0786515 8.5316 0.0786515 8.17961 0.43064C7.82762 0.782629 7.82762 1.35123 8.17961 1.70322L11.7814 5.28975L0.936578 5.28975C0.440183 5.28975 0.0340433 5.69589 0.0340433 6.19228C0.0340433 6.68867 0.440183 7.09482 0.936578 7.09482Z" fill="#151515"/>
                        </svg>
                    </button>
                </div>  
            </div>
            <div className="class-auth-footer">
                <p className='footer-text'>School is not listed? <Link className='footer-link' to="/school-list">Add school</Link>
                </p>
            </div>
        </div>
    );
};

export default SchoolList;