import React from 'react';
import './classroom.css';
import Sidebar from './sidebar/sidebar';
import Display from './display/display';

const Classroom = () => {

    return (
        <>
            {/*  first we need to break it down as both sidebar and main display */}
            <div className='wrapper'>
                
                {/* sidebar */}
                <Sidebar />

                {/* main display */}
                <Display />
            </div>
   
        </>
    );
}

export default Classroom;
