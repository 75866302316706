import React, { useState } from 'react';
import { FiFile, FiMoreHorizontal, FiX, FiShare2 } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import './History.css';
import { useNavigate } from 'react-router-dom';

const History = ({ items, onDelete, onShare }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();

    const handleMoreClick = (index) => {
        setSelectedItem(selectedItem === index ? null : index);
    };

    const handleDeleteClick = (index) => {
        onDelete(index);
        setSelectedItem(null);
    };

    const handleShareClick = (index) => {
        onShare(index);
        setSelectedItem(null);
    };

    const itemVariants = {
        hidden: { opacity: 0, y: -3 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -3 },
        hover: {
            scale: 1.02,
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <div className="history">
            <div className='history-wrap'>
                <h2 className='history-label'>History</h2>
            </div>

            <motion.div
                className='history-item-wrap'
                layout
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
                {/* <div className="gradient-blur"></div> */}
                <AnimatePresence>
                    {items.length === 0 ? (
                        <motion.div
                            key="empty"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="history-empty-state"
                        >
                            You have no classes yet.
                        </motion.div>
                    ) : (
                        items.map((item, index) => (
                            <motion.div
                                key={item.id}
                                className="history-item-container"
                                layout
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={itemVariants}
                                transition={{ duration: 0.3, ease: 'easeInOut' }}
                                whileHover="hover"
                            >

                                <motion.div 
                                    className="history-item"
                                    onClick={() => navigate(`/details/${index}`)}
                                >
                                    <div className='history-item-text'>
                                        <p className="history-item-title">{item.title}</p>
                                        <p className="history-item-subtitle">{item.subtitle}</p>
                                    </div>
                                    {item.file && (
                                        <div className="history-item-file">
                                            {item.file.type.startsWith('image/') ? (
                                                <img src={item.file.url} alt="uploaded" className="history-item-img" />
                                            ) : (
                                                <div className="history-file-document">
                                                    <FiFile />
                                                    <span>{item.file.name}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="more-button" onClick={(e) => {e.stopPropagation(); handleMoreClick(index);}}>
                                        <FiMoreHorizontal />
                                    </div>
                                </motion.div>
                                <AnimatePresence>
                                    {selectedItem === index && (
                                        <motion.div
                                            className="more-options"
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <button className="option-button" onClick={(e) => {e.stopPropagation(); handleDeleteClick(index);}}>
                                                <FiX /> Delete
                                            </button>
                                            <button className="option-button" onClick={(e) => {e.stopPropagation(); handleShareClick(index);}}>
                                                <FiShare2 /> Share
                                            </button>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                        ))
                    )}
                </AnimatePresence>
            </motion.div>
        </div>
    );
};

export default History;
