// src/sidebar/utils/ClassPreviewQuest.jsx
import React, { useContext } from 'react';
import { ContentContext } from './ContentContext';
import './classPreview.css';
import Question from '../../display/utils/question';

const ClassPreviewQuest = ({ label, content, contentType }) => {
  const { selectContent } = useContext(ContentContext);

  const handleClick = () => {
    selectContent(content, contentType);
  };

  return (
    <div className='class-preview' onClick={handleClick}>
      <div className='class-preview-item'>
        <div className='class-preview-item-content'>
          <Question content={content} isScaledDown={true} />
        </div>
        <div className='class-preview-item-overlay gradient-blur-p'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p className='box-label'>{label}</p>
      </div>
    </div>
  );
};

export default ClassPreviewQuest;
