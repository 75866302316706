// // src/sidebar/utils/ClassPreviewFrame.jsx
// import React, { useContext } from 'react';
// import { ContentContext } from './ContentContext';
// import './classPreview.css';
// import Frame from '../../display/utils/Frame';

// const ClassPreviewFrame = ({ label, content, contentType }) => {
//     const { selectContent } = useContext(ContentContext);

//     const handleClick = () => {
//         selectContent(content, contentType);
//     };

//     return (
//         <div className='class-preview' onClick={handleClick}>
//             <div className='class-preview-item'>
//                 <div className='class-preview-item-content'>
//                     <Frame />
//                 </div>
//                 <div className='class-preview-item-overlay gradient-blur-p'>
//                     <div></div><div></div><div></div><div></div><div></div><div></div>
//                 </div>
//                 <p className='box-label'>{label}</p>
//             </div>
//         </div>
//     );
// };

// export default ClassPreviewFrame;


// ClassPreviewFrame.jsx
// src/sidebar/utils/ClassPreviewFrame.jsx
import React, { useContext } from 'react';
import { ContentContext } from './ContentContext';
import './classPreview.css';
import Frame from '../../display/utils/Frame';

const ClassPreviewFrame = ({ label, content, contentType }) => {
  const { selectContent, thumbnails, selectedThumbnailIndex, selectThumbnail } = useContext(ContentContext);

  const handleClick = () => {
    selectContent(content, contentType);
  };

  return (
    <div className='class-preview' onClick={handleClick}>
      <div className='class-preview-item'>
        <div className='class-preview-item-content'>
          <Frame isScaledDown={true} readOnly={true} />
        </div>
        <div className='class-preview-item-overlay gradient-blur-p'>
          <div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
        <p className='box-label'>{label}</p>
      </div>
    </div>
  );
};

export default ClassPreviewFrame;
