import React, { useContext } from 'react';
import ClassPreviewQuest from './classPreviewQuest';
import ClassPreviewFrame from './classPreviewFrame';
import { ContentContext } from './ContentContext';
import './classBody.css';

const ClassBody = () => {
  const { content } = useContext(ContentContext);

  return (
    <div className='class-body'>
      <div className='class-body-section'>
        <div className='section-header'>
          <h2 className='h2-small'>Class</h2>
        </div>
        <ClassPreviewFrame label="S1" content={content.summary} contentType="class" />
      </div>
      <div className='class-body-section section-2'>
        <div className='section-header'>
          <h2 className='h2-small'>Questions</h2>
        </div>
        <div className='questions-section'>
          {content.questions.map((question, index) => (
            <ClassPreviewQuest key={index} label={`Q${index + 1}`} content={question.content} contentType="question" />
          ))}
        </div>
        <div className='gradient-blur-top'></div> 
        <div className='gradient-blur'></div>            
      </div>
    </div>
  );
};

export default ClassBody;
