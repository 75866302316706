import React, { useContext, useState } from 'react';
import { ContentContext } from './utils/ContentContext';
import './display.css';
import FooterDiv from '../display/utils/footDisp';
import DisplayBody from './utils/displayBody';

const Display = () => {
    const { content } = useContext(ContentContext);

    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (value) => {
      setInputValue(value);
    };
  
    const handleSendClick = (newItem) => {
      console.log("New item sent to backend:", newItem);
      // Simulate backend response
      setTimeout(() => {
        console.log("Backend responded with audio file");
      }, 2000);
    };
  
    const handleFileChange = (event) => {
      console.log("Files selected:", event.target.files);
    };

    return (
        <div className='display'>
            <div className='display-header'>
                <div className='headertitle'>
                    <h2 className='h2-m'>Control Systems</h2>
                </div>
            </div>


          {/* this is the display body component instance */}
            <DisplayBody />

            <div className='display-footer'>
                <FooterDiv
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    onSendClick={handleSendClick}
                    onFileChange={handleFileChange}
                />
            </div>
        </div>
    );
};

// this is the display component responsible for rendering whether or not the Frame or Question component should be rendered.... for real this is a pretty complex 
export default Display;
