import React, { createContext, useState } from 'react';
import Dummy from '../../display/utils/data.json';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [content, setContent] = useState(Dummy);
  const [thumbnails, setThumbnails] = useState(content.thumbnails || []);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const selectContent = (content, type) => {
    setSelectedContent({ content, type });
  };

  const selectThumbnail = (index) => {
    setSelectedThumbnailIndex(index);
    setCurrentIndex(index); // Update currentIndex when thumbnail is selected
  };

  const addThumbnail = (thumbnail) => {
    setThumbnails((prevThumbnails) => [...prevThumbnails, thumbnail]);
  };

  return (
    <ContentContext.Provider
      value={{
        content,
        selectedContent,
        selectContent,
        thumbnails,
        selectedThumbnailIndex,
        selectThumbnail,
        addThumbnail,
        currentIndex,
        setCurrentIndex,
        hoveredIndex,
        setHoveredIndex,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
