// src/Sidebar.jsx
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import ClassBody from './utils/classBody';
import './sidebar.css';
import { useNavigate } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

const Sidebar = () => {
    const courseTitle = "Control Systems";
    const courseSubtitle = "Fast Fourier Transforms";
    const courseProfile = "Oyebamijo";
    const department = "Mechatronics Eng.";
    const navigate = useNavigate();

    return (
        <div className='sidebar'>
            <div className='class-sidebar-header' onClick={() => navigate('/dock')}>
                <FiChevronLeft className='class-siderbar-header-chevron' />
                <div className='class-sidebar-header-title-wrap'>
                    <p className='class-sidebar-header-title-head'>{courseTitle}</p>
                    <p className='class-sidebar-header-title-subhead'>{courseSubtitle}</p>
                </div>
            </div>
            <ClassBody /> 
            <div className='class-sidebar-footer'>
                <div className='class-siderbar-footer-profile-img'>
                    <FaUserCircle size={35} />
                </div>
                <div className='class-sidebar-footer-profile-wrap'>
                    <p className='class-sidebar-footer-profile-small'>{courseProfile}</p>
                    <p className='class-sidebar-footer-profile-smaller'>{department}</p>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
