import React, { useState } from 'react';
import History from '../tinyComponents/History';
import '../onboarding/classAuth.css';
import Footer from './display/utils/footer';
// import FooterDiv from './display/utils/footDisp';

const Dock = () => {
    const [inputValue, setInputValue] = useState("");
    const [historyItems, setHistoryItems] = useState([
        { id: 1, title: "Control Systems", subtitle: "Fast Fourier Transforms", isNew: false },
        { id: 2, title: "Thermodynamics", subtitle: "Internal Combustion Engine", isNew: false },
        { id: 3, title: "Engineering Mathematics", subtitle: "First Order Differential Equations", isNew: false }
    ]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFileChange = (event) => {
        // The file handling logic will be in the Footer component
    };

    const handleSendClick = (newItem) => {
        const newHistoryItem = {
            ...newItem,
            id: Date.now(), // Unique ID
            isNew: true     // Mark as new
        };
        setHistoryItems([newHistoryItem, ...historyItems.map(item => ({ ...item, isNew: false }))]);
        setInputValue("");
    };

    const handleDelete = (index) => {
        const newItems = historyItems.filter((_, i) => i !== index);
        setHistoryItems(newItems.map((item, i) => ({ ...item, isNew: i === 0 })));
    };

    return (
        <div className="class-auth">
            <div className="class-auth-header">
                <div className='logo-container'>
                    <img src="/tooig-classroom-logo.png" alt="your-logo-here" className='logo' />
                    <div className='image-cover'></div>
                </div>
            </div>
            <div className="class-auth-body">
                <h1>What do you want to learn?</h1>

                <Footer
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    onSendClick={handleSendClick}
                    onFileChange={handleFileChange}
                />

                {/* <FooterDiv /> */}
                
                <History items={historyItems} onDelete={handleDelete} />

 
            </div>
        </div>
    );
};

export default Dock;
