// import React, { useState, useEffect, useRef } from "react";
// import { FaApple, FaAndroid, FaWindows, FaLinux, FaGithub } from "react-icons/fa";
// import "./carousel.css";

// const Carousel = ({ onItemSelected, onNewItemAdded }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const frameRef = useRef(null);

//   const icons = [FaApple, FaAndroid, FaWindows, FaLinux, FaGithub];

//   const createNewDiv = (id) => {
//     const Icon = icons[id % icons.length]; // Select an icon based on the id
//     return (
//       <div
//         className={`new-div ${currentIndex === id ? "current" : hoveredIndex === id ? "hovered" : ""}`}
//         key={id}
//         onClick={() => handleDivClick(id)}
//         onMouseEnter={() => handleDivMouseEnter(id)}
//         onMouseLeave={() => handleDivMouseLeave()}
//       >
//         <Icon size={32} />
//         <p>Item {id + 1}</p>
//       </div>
//     );
//   };

//   const [divs, setDivs] = useState([createNewDiv(0)]);

//   const addNewDiv = () => {
//     setDivs((prevDivs) => {
//       const newDivs = [...prevDivs, createNewDiv(prevDivs.length)];
//       setCurrentIndex(prevDivs.length); // Set current index to the new div
//       onNewItemAdded(prevDivs.length); // Notify parent component
//       return newDivs;
//     });
//   };

//   const handleDivClick = (index) => {
//     setCurrentIndex(index);
//     setHoveredIndex(null); // Clear hover state when an item is clicked
//     onItemSelected(index); // Call the handler function passed from Frame component
//   };

//   const handleDivMouseEnter = (index) => {
//     setHoveredIndex(index);
//   };

//   const handleDivMouseLeave = () => {
//     setHoveredIndex(null);
//   };

//   const handleLeftClick = () => {
//     if (currentIndex > 0) {
//       const newIndex = currentIndex - 1;
//       setCurrentIndex(newIndex);
//       onItemSelected(newIndex); // Notify parent component
//     }
//   };

//   const handleRightClick = () => {
//     if (currentIndex < divs.length - 1) {
//       const newIndex = currentIndex + 1;
//       setCurrentIndex(newIndex);
//       onItemSelected(newIndex); // Notify parent component
//     }
//   };

//   useEffect(() => {
//     // Scroll to the current item when currentIndex changes
//     const currentDiv = frameRef.current.children[currentIndex];
//     if (currentDiv) {
//       currentDiv.scrollIntoView({ behavior: "smooth", inline: "center" });
//     }
//   }, [currentIndex]);

//   return (
//     <div className="frame-container">
//       <div className="controls">
//         <button className="left-btn" onClick={handleLeftClick}>
//           &lt;
//         </button>
//         <div className="frame" ref={frameRef}>
//           {divs.map((div, index) =>
//             React.cloneElement(div, {
//               className: `new-div ${
//                 currentIndex === index
//                   ? "current"
//                   : hoveredIndex === index
//                   ? "hovered"
//                   : ""
//               }`,
//             })
//           )}
//         </div>
//         <button className="right-btn" onClick={handleRightClick}>
//           &gt;
//         </button>
//       </div>
//       <button className="create-btn" onClick={addNewDiv}>
//         Add Item
//       </button>
//     </div>
//   );
// };

// export default Carousel;




import React, { useContext, useEffect, useRef } from "react";
import "./carousel.css";
import { ContentContext } from "./ContentContext";

const Carousel = ({ onItemSelected, onNewItemAdded, isReadOnly = false, isScaledDown = false }) => {
  const { thumbnails, currentIndex, setCurrentIndex, hoveredIndex, setHoveredIndex, selectThumbnail } = useContext(ContentContext);
  const frameRef = useRef(null);

  const createNewDiv = (id) => {
    const thumbnail = thumbnails[id] || {};
    return (
      <div
        className={`new-div ${currentIndex === id ? "current" : hoveredIndex === id ? "hovered" : ""}`}
        key={id}
        onClick={() => handleDivClick(id)}
        onMouseEnter={() => handleDivMouseEnter(id)}
        onMouseLeave={() => handleDivMouseLeave()}
      >
        <div className={`thumbnail-content ${isScaledDown ? 'scaled-down-thumbnail' : ''}`}>
          <p>{thumbnail.thumbnail || `Item ${id + 1}`}</p>
        </div>
      </div>
    );
  };

  const addNewDiv = () => {
    if (isReadOnly) return;
    const newThumbnail = { thumbnail: `New Item ${thumbnails.length + 1}` };
    onNewItemAdded(newThumbnail);
    // Automatically set the new item as the current index
    setCurrentIndex(thumbnails.length); // Set to the new item's index
    selectThumbnail(thumbnails.length); // Update the selected thumbnail in context
  };

  const handleDivClick = (index) => {
    if (isReadOnly) return;
    setCurrentIndex(index);
    setHoveredIndex(null);
    onItemSelected(index);
    selectThumbnail(index);
  };

  const handleDivMouseEnter = (index) => {
    if (isReadOnly) return;
    setHoveredIndex(index);
  };

  const handleDivMouseLeave = () => {
    if (isReadOnly) return;
    setHoveredIndex(null);
  };

  const handleLeftClick = () => {
    if (isReadOnly) return;
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      onItemSelected(newIndex);
      selectThumbnail(newIndex);
    }
  };

  const handleRightClick = () => {
    if (isReadOnly) return;
    if (currentIndex < thumbnails.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      onItemSelected(newIndex);
      selectThumbnail(newIndex);
    }
  };

  useEffect(() => {
    const currentDiv = frameRef.current.children[currentIndex];
    if (currentDiv) {
      currentDiv.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  }, [currentIndex]);

  return (
    <div className={`frame-container ${isScaledDown ? 'scaled-down-carousel' : ''}`}>
      <div className="controls">
        <button className="left-btn" onClick={handleLeftClick} disabled={isReadOnly}>
          &lt;
        </button>
        <div className="frame" ref={frameRef}>
          {thumbnails.map((_, index) => createNewDiv(index))}
        </div>
        <button className="right-btn" onClick={handleRightClick} disabled={isReadOnly}>
          &gt;
        </button>
      </div>
      {!isReadOnly && (
        <button className="create-btn" onClick={addNewDiv}>
          Add Item
        </button>
      )}
    </div>
  );
};

export default Carousel;
