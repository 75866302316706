// import React, { useContext } from 'react';
// import { ContentContext } from '../../sidebar/utils/ContentContext';
// import './displayBody.css';
// import Frame from './Frame';
// import Question from './question';

// const DisplayBody = () => {
//   const { selectedContent } = useContext(ContentContext);

//   if (!selectedContent) {
//     return <div className='display-body'>Select an item to view content.</div>;
//   }

//   return (
//     <div className='display-body'>
//       {selectedContent.type === 'class' ? (
//         <Frame content={selectedContent.content} />
//       ) : (
//         <Question content={selectedContent.content} />
//       )}
//     </div>
//   );
// };

// export default DisplayBody;



// DisplayBody.jsx
import React, { useContext } from 'react';
import { ContentContext } from '../../sidebar/utils/ContentContext';
import './displayBody.css';
import Frame from './Frame';
import Question from './question';

const DisplayBody = () => {
  const { selectedContent } = useContext(ContentContext);

  if (!selectedContent) {
    return <div className='display-body'>Select an item to view content.</div>;
  }

  return (
    <div className='display-body'>
      {selectedContent.type === 'class' ? (
        <Frame />
      ) : (
        <Question content={selectedContent.content} />
      )}
    </div>
  );
};

export default DisplayBody;
