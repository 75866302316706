// src/display/utils/Question.jsx
import React, { useContext } from 'react';
import { ContentContext } from './ContentContext';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import Parser from 'html-react-parser';
import './question.css';

const Question = ({ content, isScaledDown = false }) => {
  const { selectedContent } = useContext(ContentContext);

  const renderContent = (content) => {
    return Parser(content, {
      replace: (domNode) => {
        if (domNode.type === 'tag' && domNode.name === 'p') {
          return (
            <p className={isScaledDown ? 'scaled-down-text' : ''}>
              {domNode.children.map((child, index) => {
                if (child.type === 'text') {
                  const textContent = child.data;
                  return textContent.split(/(\$\$.*?\$\$|\$.*?\$)/g).map((part, i) => {
                    if (part.startsWith('$$') && part.endsWith('$$')) {
                      return <BlockMath key={i}>{part.slice(2, -2)}</BlockMath>;
                    } else if (part.startsWith('$') && part.endsWith('$')) {
                      return <InlineMath key={i}>{part.slice(1, -1)}</InlineMath>;
                    } else {
                      return <span key={i}>{part}</span>;
                    }
                  });
                } else {
                  return null;
                }
              })}
            </p>
          );
        }
        return null;
      }
    });
  };

  return <div className={`question-content ${isScaledDown ? 'scaled-down-content' : ''}`}>{renderContent(content)}</div>;
};

export default Question;
