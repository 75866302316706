import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiPlus, FiArrowRight, FiFile, FiX } from "react-icons/fi";
import "./footerDiv.css";
import Visualizer from "./Visualizer";

const FooterDiv = ({ inputValue, onInputChange, onFileChange }) => {
  const [files, setFiles] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showVisualizer, setShowVisualizer] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).slice(0, 4 - files.length);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    onFileChange(event);
    if (newFiles[0].type.startsWith("audio/")) {
      setAudioSrc(URL.createObjectURL(newFiles[0]));
    }
  };

  const isImage = (file) => file.type.startsWith("image/");

  const handleRemoveFile = (index) => {
    setIsRemoving(true);
    setTimeout(() => {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      setIsRemoving(false);
    }, 250);
  };

  const handleSendClick = () => {
    if ((inputValue && inputValue.trim() !== "") || files.length > 0) {
      const formData = new FormData();
      formData.append("message", inputValue);
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      fetch("/request", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      onInputChange("");
      setFiles([]);
      setShowVisualizer(true);
      setAudioSrc(`${process.env.PUBLIC_URL}/hello.mp3`); // Set the audio source to the hello.mp3 file in the public directory
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  const handleAudioEnd = () => {
    setShowVisualizer(false);
  };

  return (
    <div className="uploads-input-container">
      <AnimatePresence>
        {!showVisualizer && (
          <motion.div
            key="input-container"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              opacity: { duration: 0.5, ease: "easeInOut" },
              scale: { duration: 0.5, ease: "easeInOut" },
            }}
            className="absolute-container"
          >
            <div className="file-previews-container">
              <AnimatePresence initial={false}>
                {files.map((file, index) => (
                  <motion.div
                    key={index}
                    className="file-preview"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{
                      opacity: { duration: 0.3, ease: "easeInOut", delay: 0.3 },
                      x: { duration: 0.3, ease: "easeInOut", delay: 0.3 },
                    }}
                  >
                    {isImage(file) ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="file-image"
                      />
                    ) : (
                      <div className="file-document">
                        <FiFile />
                        <span>{file.name}</span>
                      </div>
                    )}
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(index)}
                    >
                      <FiX />
                    </button>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
            <div className="input-container">
              <label htmlFor="file-upload" className="file-upload-button">
                <FiPlus />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                multiple
                style={{ display: "none" }}
              />
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Message here..."
                  className="text-input"
                  value={inputValue}
                  onChange={(e) => onInputChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <span className="input-underline" />
              </div>
              <button className="send-button" onClick={handleSendClick}>
                <FiArrowRight />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showVisualizer && (
          <motion.div
            key="visualizer"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              opacity: { duration: 0.5, ease: "easeInOut" },
              scale: { type: "spring", stiffness: 260, damping: 20 },
            }}
            className="absolute-container"
          >
            <Visualizer audioSrc={audioSrc} onAudioEnd={handleAudioEnd} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FooterDiv;
