import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./visualizer.css";

const Visualizer = ({ audioSrc, onAudioEnd }) => {
  const audioRef = useRef(null);
  const barsRef = useRef([]);

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaElementSource(audioRef.current);

    source.connect(analyser);
    analyser.connect(audioContext.destination);
    analyser.fftSize = 256;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      barsRef.current.forEach((bar, index) => {
        if (bar) { // Ensure the bar is not null
          const barHeight = dataArray[index];
          bar.setAttribute("height", barHeight);
          bar.setAttribute("y", 50 - barHeight / 2); // Adjust y to expand from center
        }
      });
    };

    draw();

    return () => {
      audioContext.close();
    };
  }, [audioSrc]);

  useEffect(() => {
    const handleAudioEnd = () => {
      if (onAudioEnd) {
        onAudioEnd();
      }
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener('ended', handleAudioEnd);

    return () => {
      audioElement.removeEventListener('ended', handleAudioEnd);
    };
  }, [onAudioEnd]);

  return (
    <motion.div
      key="visualizer"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{
        opacity: { duration: 0.5, ease: "easeInOut" },
        scale: { type: "spring", stiffness: 260, damping: 20 },
      }}
    >
      <div className="visualizer-container">
        <svg
          width="115"
          height="91"
          viewBox="0 0 115 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            ref={(el) => (barsRef.current[0] = el)}
            x="4.91885"
            y="45"
            width="17.7864"
            height="0"
            rx="8.89318"
            fill="black"
          />
          <rect
            ref={(el) => (barsRef.current[1] = el)}
            x="26.8418"
            y="45"
            width="17.7864"
            height="0"
            rx="8.89318"
            fill="black"
          />
          <rect
            ref={(el) => (barsRef.current[2] = el)}
            x="48.7637"
            y="45"
            width="17.7864"
            height="0"
            rx="8.89318"
            fill="black"
          />
          <rect
            ref={(el) => (barsRef.current[3] = el)}
            x="70.6865"
            y="45"
            width="17.7864"
            height="0"
            rx="8.89318"
            fill="black"
          />
          <rect
            ref={(el) => (barsRef.current[4] = el)}
            x="92.6094"
            y="45"
            width="17.7864"
            height="0"
            rx="8.89318"
            fill="black"
          />
        </svg>
        <audio ref={audioRef} src={audioSrc} autoPlay />
      </div>
    </motion.div>
  );
};

export default Visualizer;
