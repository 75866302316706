// import React, { useState, useContext } from "react";
// import Carousel from "./carousel";
// import "./frame.css";
// import { ContentContext } from './ContentContext';

// const Frame = () => {
//   const { content } = useContext(ContentContext);
//   const [selectedItem, setSelectedItem] = useState(0);

//   const handleItemSelected = (index) => {
//     setSelectedItem(index);
//   };

//   const handleNewItemAdded = (index) => {
//     setSelectedItem(index);
//   };

//   if (!content || !content.thumbnails || content.thumbnails.length === 0) {
//     console.log('Content or Thumbnails not available', content);
//     return <div>Loading...</div>;
//   }

//   const selectedThumbnail = content.thumbnails[selectedItem];

//   return (
//     <div className="frame-wrap">
//       <div className="body-frame">
//         <p>Selected Item: {selectedItem + 1}</p>
//         <p>{selectedThumbnail ? selectedThumbnail.content : 'No content available'}</p>
//       </div>
//       <div className="body-frame-carousel">
//         <Carousel
//           onItemSelected={handleItemSelected}
//           onNewItemAdded={handleNewItemAdded}
//         />
//       </div>
//     </div>
//   );
// };

// export default Frame;

// Frame.jsx
// src/display/utils/Frame.jsx
import React, { useContext } from 'react';
import Carousel from './carousel';
import './frame.css';
import { ContentContext } from './ContentContext';

const Frame = ({ isScaledDown = false, readOnly = false }) => {
  const { thumbnails, selectedThumbnailIndex, selectThumbnail, addThumbnail } = useContext(ContentContext);

  const handleItemSelected = (index) => {
    if (!readOnly) {
      selectThumbnail(index);
    }
  };

  const handleNewItemAdded = () => {
    if (!readOnly) {
      const newThumbnail = { content: `New Content ${thumbnails.length + 1}` };
      addThumbnail(newThumbnail);
    }
  };

  if (!thumbnails || thumbnails.length === 0) {
    return <div>Loading...</div>;
  }

  const selectedThumbnail = thumbnails[selectedThumbnailIndex];

  return (
    <div className={`frame-wrap ${isScaledDown ? 'scaled-down-frame' : ''}`}>
      <div className="body-frame">
        <p>Selected Item: {selectedThumbnailIndex + 1}</p>
        <p>{selectedThumbnail ? selectedThumbnail.content : 'No content available'}</p>
      </div>
      <div className="body-frame-carousel">
        <Carousel
          onItemSelected={handleItemSelected}
          onNewItemAdded={handleNewItemAdded}
          isReadOnly={readOnly}
          isScaledDown={isScaledDown}
        />
      </div>
    </div>
  );
};

export default Frame;
