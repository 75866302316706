import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiPlus, FiArrowRight, FiFile, FiX } from 'react-icons/fi';
import './footer.css';

const Footer = ({ inputValue, onInputChange, onSendClick, onFileChange }) => {
    const [files, setFiles] = useState([]);
    const [isRemoving, setIsRemoving] = useState(false);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files).slice(0, 4 - files.length);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        onFileChange(event);
    };

    const isImage = (file) => file.type.startsWith('image/');

    const handleRemoveFile = (index) => {
        setIsRemoving(true);
        setTimeout(() => {
            setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            setIsRemoving(false);
        }, 250); // Delay to match the exit transition
    };

    const handleSendClick = () => {
        if (inputValue.trim() !== "" || files.length > 0) {
            const firstFile = files.length > 0 ? {
                name: files[0].name,
                type: files[0].type,
                url: URL.createObjectURL(files[0])
            } : null;
            const newItem = {
                title: "New Topic",
                subtitle: inputValue,
                file: firstFile
            };
            onSendClick(newItem);
            setFiles([]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendClick();
        }
    };

    return (
        <div className="upload-input-container">
            <motion.div
                className="file-previews-container"
                layout
                initial={{ height: 0 }}
                animate={{ height: files.length > 0 || isRemoving ? 'auto' : 0 }}
                transition={{
                    height: { duration: isRemoving ? 0.6 : 0.3, ease: 'easeInOut', delay: isRemoving ? 0 : 0.3 },
                }}
            >
                <AnimatePresence initial={false}>
                    {files.map((file, index) => (
                        <motion.div
                            key={index}
                            className="file-preview"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            transition={{
                                opacity: { duration: 0.3, ease: 'easeInOut', delay: 0.3 },
                                x: { duration: 0.3, ease: 'easeInOut', delay: 0.3 },
                            }}
                        >
                            {isImage(file) ? (
                                <img src={URL.createObjectURL(file)} alt={file.name} className="file-image" />
                            ) : (
                                <div className="file-document">
                                    <FiFile />
                                    <span>{file.name}</span>
                                </div>
                            )}
                            <button className="remove-button" onClick={() => handleRemoveFile(index)}>
                                <FiX />
                            </button>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </motion.div>
            <div className="input-container">
                <label htmlFor="file-upload" className="file-upload-button">
                    <FiPlus />
                </label>
                <input
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    style={{ display: 'none' }}
                />
                <div className="input-wrapper">
                    <input
                        type="text"
                        placeholder="Message here..."
                        className="text-input"
                        value={inputValue}
                        onChange={onInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <span className="input-underline" />
                </div>
                <button className="send-button" onClick={handleSendClick}>
                    <FiArrowRight />
                </button>
            </div>
        </div>
    );
};

export default Footer;
