import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../index.css';
import SchoolList from './onboarding/SchoolList.jsx';
import ClassAuth from './onboarding/class-auth.jsx';
import BetaAuth from './auth/betaAuth.jsx';
import Dock from './main/classDock.jsx';
import Classroom from './main/classroom.jsx';
import { ContentProvider } from './main/display/utils/ContentContext.jsx';

function App() {
  return (
    <Router>
      <ContentProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<BetaAuth />} />
            <Route path="/class" element={<ClassAuth />} />
            <Route path="/school" element={ <SchoolList />} />
            <Route path="/dock" element={<Dock />} />
            <Route path="/classroom" element={<Classroom />} />
          </Routes>

          {/* this is the utility part of the website... it handles analytics and some speed insights from vercel and all */}
          <Analytics />
          <SpeedInsights />
        </div>
      </ContentProvider>
    </Router>
  );
}

export default App;
